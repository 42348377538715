import React from 'react';
import axios from 'axios';
import './index.css';
import App from './App';
import AuthProvider from './components/auth/AuthContext';
import OneOffAuthProvider from './components/oneOffAuth/OneOffAuthContext';
import Keycloak from 'keycloak-js';
import { BrowserRouter as Router } from 'react-router-dom';
import { handleTokenExpiredEvent } from './components/auth/TokenUtils';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { getConfig } from './Config';
import { createRoot } from 'react-dom/client';

const setUpApp = async () => {
  let keycloakConfig;
  if (window.ENV === 'Drone') {
    keycloakConfig = '/config/keycloak_drone.json';
  } else {
    keycloakConfig = '/config/keycloak.json';
  }
  const keycloak = Keycloak(keycloakConfig);
  const matomoSiteId = getConfig().matomoSiteId;
  const matomoUrl = getConfig().matomoUrl;
  keycloak.onAuthSuccess = async () => {
    sessionStorage.setItem('token', keycloak.token);
    sessionStorage.setItem('idToken', keycloak.idToken);
    sessionStorage.setItem('tokenData', JSON.stringify(keycloak.tokenParsed));
    sessionStorage.setItem('authenticated', 'true');
  };

  axios.interceptors.request.use(async (request) => {
    request.headers.Authorization = keycloak.token;
    return request;
  });

  const instance = createInstance(
    {
      urlBase: matomoUrl,
      siteId: matomoSiteId,
      trackerUrl: `${matomoUrl}/matomo.php`,
      srcUrl: `${matomoUrl}/matomo.js`,
      disabled: false,
      heartBeat: {
        active: true,
        seconds: 10,
      },
      linkTracking: false,
      configurations: {
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: 'POST',
      },
    },
    [matomoSiteId, matomoUrl]
  );

  const renderApp = async () => {
    const idToken = sessionStorage.getItem('idToken');
    const isAuthenticated = await keycloak.init({
      promiseType: 'native',
      flow: 'hybrid',
      checkLoginIframe: false,
      redirectUri: window.location,
      onLoad: 'check-sso',
      idToken,
    });
    sessionStorage.setItem('authenticated', isAuthenticated.toString());
    const root = createRoot(document.getElementById('root'));
    root.render(
      <MatomoProvider value={instance}>
        <AuthProvider keycloak={keycloak}>
          <OneOffAuthProvider>
            <Router>
              <App keycloak={keycloak} />
            </Router>
          </OneOffAuthProvider>
        </AuthProvider>
      </MatomoProvider>
    );
  };

  handleTokenExpiredEvent(keycloak);
  await renderApp();
};

await setUpApp();
