import axios from 'axios';

export const handleTokenExpiredEvent = (keycloak) => {
  keycloak.onTokenExpired = () => {
    keycloak
      .updateToken(300)
      .then(() => {
        axios.interceptors.request.use((request) => {
          if (keycloak.token) {
            request.headers.Authorization = keycloak.token;
          }
          return request;
        });
      })
      .catch(() => {
        // If updating the token fails invalidate the session to force login when user performs an action
        keycloak.clearToken();
        keycloak.init({
          promiseType: 'native',
          checkLoginIframe: false,
        });
      });
  };
};
